import DashboardLayout from "../layouts/DashboardLayout";
import AddButton from "../components/AddButton";
import SlideOver from "../components/SlideOver";
import AddForm from "../components/AddForm";
import {useState} from "react";
import MainContainer from "../components/MainContainer";
import StatList from "../components/StatList";
import EmptyState from "../components/EmptyState";

const stats = [
    {
        id: 2,
        date: 'June 22, 2024',
        dateTime: '2024-06-22T00:00Z',
        arrest: 'Felony',
        contraband: 'Heroin',
        location: 'County'
    },
    {
        id: 1,
        date: 'June 20, 2024',
        dateTime: '2024-06-20T00:00Z',
        arrest: 'Felony',
        contraband: 'Cocaine',
        location: 'Lake Elsinore'
    },
    {
        id: 3,
        date: 'June 19, 2024',
        dateTime: '2024-06-19T00:00Z',
        arrest: 'Misdemeanor',
        contraband: 'Methamphetamine',
        location: 'Wildomar'
    }
]

export default function Dashboard() {
    const [isSlideOverOpen, setSlideOverOpen] = useState(false)
    const [isEmpty, setIsEmpty] = useState(true);

    const handleAdd = () => {
        setIsEmpty(false);
        setSlideOverOpen(false);
    }

    return (
        <DashboardLayout>
            <MainContainer>
                {isEmpty ? <EmptyState onAdd={() => setSlideOverOpen(true)}/> :
                    <StatList onAdd={() => setSlideOverOpen(true)} stats={stats}/>}
            </MainContainer>

            <div className="fixed bottom-8 w-full">
                <AddButton onClick={() => setSlideOverOpen(true)}/>
            </div>

            <SlideOver isOpen={isSlideOverOpen} setOpen={setSlideOverOpen} title="Add Stat">
                <AddForm onCancel={() => setSlideOverOpen(false)} onAdd={handleAdd} />
            </SlideOver>
        </DashboardLayout>
    );
}
