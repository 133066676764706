import {PlusIcon} from "@heroicons/react/20/solid";

export default function AddButton({ onClick }) {
    return (
        <button
            type="button"
            className="rounded-full bg-indigo-600 p-2 text-white shadow-sm float-right mr-8 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={onClick}
        >
            <PlusIcon className="h-8 w-8" aria-hidden="true"/>
        </button>
    )
}