import {useEffect} from "react";

export default function Dashboard({children}) {
    useEffect(() => {
        document.body.classList.replace('bg-gray-900', 'bg-gray-100');
    }, []);

    return (
        <>
            <div className="min-h-full">
                <div className="bg-gray-800 pb-32">
                    <header className="py-10">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-white text-center">StatItUp</h1>
                        </div>
                    </header>
                </div>
                {children}
            </div>
        </>
    )
}
