export default function AddForm({ onAdd, onCancel }) {
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div className="sm:col-span-3">
                            <label htmlFor="location"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Location
                            </label>
                            <div className="mt-2">
                                <select
                                    id="location"
                                    name="location"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option value=""></option>
                                    <option value="wildomar">Wildomar</option>
                                    <option value="lake-elsinore">Lake Elsinore</option>
                                    <option value="county">County</option>
                                </select>
                            </div>
                        </div>


                        <div className="sm:col-span-3">
                            <label htmlFor="arrest"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Arrest
                            </label>
                            <div className="mt-2">
                                <select
                                    id="arrest"
                                    name="arrest"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option value=""></option>
                                    <option value="arrest">Felony</option>
                                    <option value="arrest">Misdemeanor</option>
                                </select>
                            </div>
                        </div>


                        <div className="sm:col-span-3">
                            <label htmlFor="contraband"
                                   className="block text-sm font-medium leading-6 text-gray-900">
                                Contraband
                            </label>
                            <div className="mt-2">
                                <select
                                    id="contraband"
                                    name="contraband"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                >
                                    <option value=""></option>
                                    <option value="meth">Methamphetamine</option>
                                    <option value="fentanyl">Fentanyl</option>
                                    <option value="heroin">Heroin</option>
                                    <option value="cocaine">Cocaine</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={onCancel}>
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={onAdd}
                >
                    Add
                </button>
            </div>
        </form>
    )
}