import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StatList({onAdd, stats}) {
    return (
        <>
            <div className="sm:flex sm:items-center border-b border-gray-900/10 pb-6">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Your Stats</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all your recorded encounters
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={onAdd}
                    >
                        Add Stat
                    </button>
                </div>
            </div>
            <ul className="divide-y divide-gray-100">
                {stats.map((record) => (
                    <li key={record.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{record.contraband}</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">
                                    <time dateTime={record.dateTime}>{record.date}</time>
                                </p>
                                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                    <circle cx={1} cy={1} r={1}/>
                                </svg>
                                <p className="whitespace-nowrap">
                                    {record.location}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <Menu as="div" className="relative flex-none">
                                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                </MenuButton>
                                <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                >
                                    <MenuItem>
                                        {({focus}) => (
                                            <a
                                                href="/"
                                                className={classNames(
                                                    focus ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                )}
                                            >
                                                Edit
                                            </a>
                                        )}
                                    </MenuItem>
                                    <MenuItem>
                                        {({focus}) => (
                                            <a
                                                href="/"
                                                className={classNames(
                                                    focus ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                )}
                                            >
                                                Delete
                                            </a>
                                        )}
                                    </MenuItem>
                                </MenuItems>
                            </Menu>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}