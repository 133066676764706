export default function MainContainer({children}) {
    return (
        <main className="-mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
                    {children}
                </div>
            </div>
        </main>
    )
}